<template>
    <section :class="componentThemeClass">
        <Container container-class="py-16 px-4 lg:py-32 lg:max-w-7xl">
            <div class="grid grid-cols-12 gap-4">
                <template v-for="(panel, index) in panels" :key="index">
                    <div class="relative col-span-12 lg:col-span-6" data-aos="fade-in" :data-aos-delay="index * 50">
                        <img :src="panel.image.url" alt="Image: Left Column"/>
                        <a :href="panel.link_url" :title="'Link: ' + panel.title" class="flex items-center justify-center absolute top-0 bottom-0 left-0 right-0">
                            <div class="flex flex-col w-full h-full p-5 justify-end">
                                <h3 class="mb-0">{{ panel.title }}</h3>
                                <span v-if="panel.content" v-html="panel.content" class="mt-1"></span>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'ImageGridSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            title: String,
            panels: Array
        }
    }
</script>