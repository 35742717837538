<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <TextMasthead :title="pageTitle" :sub-title="tag ? tag.name : null" :text="pageIntro" :background-colour="_backgroundColour" :text-colour="_textColour" />
            <main>
                <section class="bg-white">
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="flex justify-between items-center mb-4">
                            <template v-if="categories">
                                <div class="relative">
                                    <a @click.prevent="filtersOpen = !filtersOpen" class="text-xl text-primary uppercase">
                                        <AdjustmentsIcon class="inline h-6 w-6 mr-2" /> Filters ({{selectedCategoriesCount}})
                                    </a>
                                    <div v-if="filtersOpen" class="w-300px filter border drop-shadow-lg absolute top-12 left-0 p-8 z-10 bg-white max-h-screen overflow-scroll lg:w-450px lg:top-16">
                                        <Accordion v-for="(category, index) in categories" :key="index" :title="category.title" class="py-4" icon-style="arrow">
                                            <div class="pr-2">
                                                <template  v-for="option in category.category_options" :key="option.id">
                                                    <label :for="option.name" class="block flex justify-between mb-1">{{ option.name }} <input :id="option.name" type="checkbox" @change="filterChange()" v-model="selectedCategories[category.id]" :value="option.id"></label>
                                                </template>
                                            </div>
                                        </Accordion>
                                        <Accordion title="Price" class="py-4" icon-style="arrow">
                                            <div>
                                                <label class="block mb-2">£{{ priceLower}} - £{{ priceRangeHigher }}</label>
                                                <div class="flex border bg-gray-100 rounded-full">
                                                    <input v-model="priceLower" type="range"
                                                           min="0" max="100" step="1" class="appearance-none bg-transparent w-1/3" @change="filterChange()" />
                                                    <input v-model="priceHigher" type="range"
                                                           :min="parseInt(priceLower) + 1" max="1000" step="10" class="appearance-none bg-transparent w-2/3" @change="filterChange()" />
                                                </div>
                                            </div>
                                        </Accordion>
                                        <Button @click.prevent="resetCategoryFilters(true)" background-colour="transparent" text-colour="black" border-colour="black" class="w-full mt-6">
                                            Clear Filters
                                        </Button>
                                    </div>
                                </div>
                            </template>
                            <div class="flex items-center">
                                <!--<label for="sort" class="hidden text-xl mr-2 lg:flex">Sort by:</label>-->
                                <select id="sort" @change="sortChange($event)" class="bg-transparent border-0 text-xl px-0 background-none text-primary uppercase">
                                    <option v-for="(option, index) in sortingOptions" :key="index"
                                            :selected="sorting.property === option.property && sorting.direction === option.direction"
                                            :value="option.property + '|' + option.direction">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="min-h-24">
                            <div v-if="products && products.length > 0"
                                 class="grid grid-cols-12 gap-4 mb-8 lg:mb-16">
                                <div v-for="(product, index) in products" :key="index" class="col-span-6 md:col-span-4 lg:col-span-3" data-aos="fade-in" :data-aos-delay="index">
                                    <Product :product="product" />
                                </div>
                            </div>
                            <div v-if="!this.loadingProducts && products.length === 0">
                                <p class="text-2xl mb-0 h-24 flex justify-center items-center">
                                    <span>No results found</span>
                                </p>
                            </div>
                            <template v-if="this.loadingProducts === true">
                                <div class="relative h-24">
                                    <Loading/>
                                </div>
                            </template>
                            <div v-if="showLoadMore" class="mt-6 lg:mt-8" data-aos="fade-up">
                                <span v-if="this.products.length > 0" class="text-sm text-center block">Showing {{ this.products.length }} products</span>
                                <Button @click.prevent="loadMoreProducts" :class="'mt-2 mx-auto w-full block lg:max-w-xl'">{{ this.loadingProducts ? 'Loading...' : 'Load more' }}</Button>
                            </div>
                        </div>
                    </Container>
                </section>
                <ContentSections v-if="pageContent" :pageContent="pageContent"/>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import Footer from '@/components/Footer.vue'
    import Product from '@/components/elements/Product.vue'
    import Button from '@/components/elements/Button.vue'
    import PageContent from '@/mixins/pageContent'
    import MoneyFormat from '@/mixins/moneyFormat'
    import Loading from "../components/Loading";
    import Container from '@/components/Container'
    import Accordion from '@/components/elements/Accordion.vue'
    import { AdjustmentsIcon } from '@heroicons/vue/solid'
    import axios from "axios";

    export default {
        name: 'ProductListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageContent, MoneyFormat],
        components: {
            Container,
            GlobalAlert,
            Loading,
            TextMasthead,
            ContentSections,
            Footer,
            Product,
            Button,
            Accordion,
            AdjustmentsIcon
        },
        data() {
            return {
                page: 1,
                pagination_interval: 16,
                sorting: {
                    property: 'created_at',
                    direction: 'desc'
                },
                sortingOptions: [
                    {
                        property: 'created_at',
                        direction: 'desc',
                        label: 'Newest'
                    },
                    {
                        property: 'price',
                        direction: 'asc',
                        label: 'Price Low to High'
                    },
                    {
                        property: 'price',
                        direction: 'desc',
                        label: 'Price High to Low'
                    },
                    {
                        property: 'title',
                        direction: 'asc',
                        label: 'Title (A-Z)'
                    },
                    {
                        property: 'title',
                        direction: 'desc',
                        label: 'Title (Z-A)'
                    }
                ],
                filters: [],
                priceLower: 0,
                priceHigher: 100,
                selectedCategories: [],
                products: null,
                productType: null,
                tag: null,
                categories: null,
                loaded: false,
                filtersOpen: false,
                showLoadMore: false,
                loadingProducts: false
            };
        },
        computed: {
            priceRangeHigher: function(){
                return parseInt(this.priceLower) + 1 > this.priceHigher ? this.priceLower : this.priceHigher
            },
            selectedCategoriesCount: function () {
                let count = 0
                this.selectedCategories.forEach(value => {
                    count += value.length
                })

                return count
            },
            pageSlug: function () {
                return 'products';
            },
            pageTitle: function () {
                if (this.productType) {
                    return this.productType.title
                }

                return 'All products';
            },
            pageIntro: function () {
                if (this.productType && this.productType.description) {
                    return this.productType.description
                }

                return null;
            },
            pagination: function () {
                return {
                    page: this.page,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            loadMoreProducts: function () {
                this.page++
                this.loadProducts(true, this.pagination, this.filters, [this.sorting]);
            },
            filterChange: function () {
                this.page = 1;
                let filters = []
                this.selectedCategories.forEach(value => {
                    if(value.length > 0) {
                        filters.push(
                            {
                                property: 'category_options',
                                value: value,
                                operator: 'whereIn'
                            }
                        )
                    }
                })

                this.filters = filters
                this.loadProducts(false, this.pagination, this.filters, [this.sorting]);
            },
            resetCategoryFilters: function(reloadProducts = false) {
                this.categories.forEach(category => {
                    this.selectedCategories[category.id] = []
                })

                if(reloadProducts) {
                    this.loadProducts(false, this.pagination, this.filters, [this.sorting])
                    this.filtersOpen = false
                }
            },
            sortChange: function (event) {
                const values = event.target.value.split('|')
                this.sorting = {
                    property: values[0],
                    direction: values[1]
                }
                this.loadProducts(false, this.pagination, this.filters, [this.sorting]);
            },
            async loadProducts(append = false, pagination = {page: 1}, filters = [], sort = [], includes = []) {
                this.loadingProducts = true
                if (!append) {
                    this.products = [];
                    this.showLoadMore = false
                }

                filters.push({
                    property: 'price',
                    value: [this.priceLower, this.priceRangeHigher],
                    operator: 'whereBetween'
                })

                if(this.productType) {
                    filters.push({
                        property: 'product_type_id',
                        value: this.productType.id,
                        operator: '='
                    })
                }

                if(this.tag) {
                    filters.push({
                        property: 'tags',
                        value: this.tag.id,
                        operator: '='
                    })
                }

                return this.sdk.getProducts(pagination, filters, sort, includes)
                    .then(response => {
                        if (append) {
                            this.products = [...this.products, ...response.products]
                        } else {
                            this.products = response.products;
                        }

                        this.showLoadMore = response.current_page !== response.last_page
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    }).finally(() => {
                        this.loadingProducts = false
                        this.loaded = true
                    });
            },
            async loadProductType () {
                const typeSlug = this.$route.params.type;
                return this.sdk.getProductTypeBySlug(typeSlug)
                    .then(response => {
                        if (response.product_types.length === 0) {
                            this.$router.push({name: '404'});
                            return;
                        }

                        this.productType = response.product_types[0];
                        this.categories = response.product_types[0].categories;
                        this.setPageContentMeta(this.store.name, this.productType.title + ' Products')
                        this.resetCategoryFilters()
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            async loadCategories () {
                return this.sdk.getCategories()
                    .then(response => {
                        this.categories = response.categories;
                        this.setPageContentMeta(this.store.name, 'Products')
                        this.resetCategoryFilters()
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            async loadTag() {
                if (this.$route.query.tag) {
                    return this.sdk.getTagBySlug(this.$route.query.tag)
                        .then(response => {
                            this.tag = response.tags[0]
                        }).catch(error => {
                            this.errorHandler.handleError(error);
                        }).finally(() => {
                            this.loadingProducts = false
                        });
                }
            },
            async loadComponentData(){
                if (this.$route.params.type) {
                    return await axios.all([this.loadPageContent(), this.loadProductType(), this.loadTag()])
                }

                return await axios.all([this.loadPageContent(), this.loadCategories(), this.loadTag()])
            }
        },
        created() {
            this.loadComponentData()
                .catch(error => {
                    this.errorHandler.handleError(error);
                })
                .finally(() => {
                    this.loadProducts(false, this.pagination, this.filters, [this.sorting])
                })

            this._backgroundColour = 'primary';
            this._textColour = 'white';
        }
    }
</script>