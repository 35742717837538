<template>
    <transition name="fade" mode="out-in">
        <div v-if="pageContent">
            <GlobalAlert />
            <Masthead v-if="masthead && masthead.value.images.length > 1"
                      :background-colour="masthead.value.background_colour"
                      :background-image="masthead.value.background_image"
                      :text-colour="masthead.value.text_colour"
                      :title="masthead.value.title"
                      :sub-title="masthead.value.sub_title"
                      :text="masthead.value.text"
                      :text-alignment="masthead.value.text_alignment"
                      :image-position="masthead.value.image_position"
                      :images="masthead.value.images"
                      :links="masthead.value.links"
            />
            <imageMasthead v-else-if="imageMasthead"
                      :background-colour="imageMasthead.value.background_colour"
                      :background-image="imageMasthead.value.image.url"
                      :text-colour="imageMasthead.value.text_colour"
                      :title="imageMasthead.value.title"
                      :sub-title="imageMasthead.value.sub_title"
                      :text="imageMasthead.value.text"
            />
            <TextMasthead v-else :title="pageContent.title" :sub_title="masthead.value.sub_title" :text="masthead.value.text" :centerText="true"/>
            <main>
                <ContentSections :pageContent="pageContent"/>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import Masthead from '@/components/Masthead.vue'
    import ImageMasthead from '@/components/ImageMasthead.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import Footer from '@/components/Footer.vue'
    import PageContent from "@/mixins/pageContent";

    export default {
        name: 'General',
        inject: ['sdk', 'dataStore'],
        mixins: [PageContent],
        components: {
            GlobalAlert,
            Masthead,
            ImageMasthead,
            TextMasthead,
            ContentSections,
            Footer
        },
        data() {
            return {
                redirect404: true
            };
        },
        computed: {
            masthead: function () {
                return this.getPageContentByKey('masthead')
            },
            imageMasthead: function () {
                return this.getPageContentByKey('image-masthead')
            }
        },
        created() {
            this.loadPageContent();
        }
    }
</script>