<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded && basket">
            <TextMasthead />
            <main>
                <Container container-class="px-4 h-screen" animation="fade-in">
                    <iframe :src="checkoutUrl" width="100%" height="100%" />
                </Container>
            </main>
            <Footer :output-navigation="false"/>
        </div>
    </transition>
</template>

<script>
    import Container from '@/components/Container'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Footer from '@/components/Footer.vue'
    import BasketActions from '@/mixins/basketActions'

    export default {
        name: 'CheckoutPage',
        inject: ['sdk', 'dataStore'],
        mixins: [BasketActions],
        components: {
            Container,
            TextMasthead,
            Footer
        },
        data() {
            return {
                loaded: false
            };
        },
        computed: {
            basket: function () {
                return this.dataStore.state.basket
            },
            store: function () {
                return this.dataStore.state.store
            },
            checkoutUrl: function () {
                return 'https://checkout.sugar-coat.io/' + this.store.slug + '/' + this.basket.basket_reference + '/?embed=true'
            }
        },
        created() {
            if (this.basket && !this.basketHasProducts) {
                this.$router.push({name: 'basket'});
                return;
            }

            this.loaded = true
        }
    }
</script>