<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <TextMasthead title="Order History" :small-heading="true" :class="'border-b border-gray'" />
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="grid grid-cols-12 gap-4 lg:gap-16">
                            <div class="mb-8 col-span-12 lg:mb-0 lg:col-span-3" data-aos="fade-right">
                                <AccountNavigation/>
                            </div>
                            <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                                <div class="overflow-scroll mb-8">
                                    <table class="w-full">
                                        <tr class="border-black border-b">
                                            <th class="text-left font-light py-4 font-bold">Order Number</th>
                                            <th class="text-left font-light py-4 font-bold">Order Details</th>
                                            <th class="text-right font-light py-4 font-bold">Status</th>
                                        </tr>
                                        <tr v-for="index in 4" :key="index" class="align-top border-black border-b">
                                            <td class="py-4">
                                                <p class="font-bold"><a href="#">37928012920</a></p>
                                            </td>
                                            <td class="py-4 lg:block">
                                                <p class="text-sm flex mb-0"><span class="mr-2">Date:</span><span>22/10/2020</span>
                                                </p>
                                                <p class="text-sm flex mb-0"><span
                                                        class="mr-2">Total:</span><span>£250</span></p>
                                            </td>
                                            <td class="py-4 text-right">
                                                <p>Shipped</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <ul class="flex justify-center">
                                    <li><a href="#" class="px-3">1</a>|</li>
                                    <li><a href="#" class="px-3">2</a>|</li>
                                    <li><a href="#" class="px-3">3</a>|</li>
                                    <li><a href="#" class="px-3">4</a></li>
                                </ul>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>

</template>

<script>
    import Container from '@/components/Container'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from "@/components/AccountNavigation";
    import Footer from '@/components/Footer.vue'

    export default {
        name: 'AccountOrderListPage',
        inject: ['dataStore'],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            Footer
        },
        data() {
            return {
                loaded: false
            };
        },
        created() {
            this.loaded = true
        }
    }
</script>