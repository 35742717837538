<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <TextMasthead />
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20 lg:max-w-4xl" animation="fade-up">
                        <h1 class="h2 mb-8">Page not found</h1>
                        <p class="text-2xl mb-16">Sorry, the page you were looking for does not exist.</p>
                        <LinkButton :href="$router.resolve({name: 'home'}).href" title="Link: Home">Back to homepage</LinkButton>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Footer from '@/components/Footer.vue'
    import Container from '@/components/Container'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'NotFoundPage',
        inject: ['dataStore'],
        components: {
            Container,
            GlobalAlert,
            TextMasthead,
            Footer,
            LinkButton
        },
        data() {
            return {
                loaded: false
            };
        },
        created() {
            this.loaded = true
        }
    }
</script>