<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <ImageMasthead v-if="productGroup.thumbnail" :title="productGroup.title" sub-title="Collection" :background-image="productGroup.thumbnail.url"
                      text-colour="white"
                      background-colour="primary"
            />
            <TextMasthead v-else :title="productGroup.title" sub-title="Collection" background-colour="primary" text-colour="white" :center-text="false" />
            <main>
                <IntroTextSection v-if="productGroup.description" :text="productGroup.description" />
                <section class="bg-white">
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="min-h-24">
                            <div v-if="productGroup.products && productGroup.products.length > 0"
                                 class="grid grid-cols-12 gap-4 mb-8 lg:mb-16">
                                <div v-for="(product, index) in productGroup.products" :key="index" class="col-span-12 lg:col-span-3" data-aos="fade-in" :data-aos-delay="index">
                                    <Product :product="product" />
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import Container from '@/components/Container'
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import ImageMasthead from '@/components/ImageMasthead.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Footer from '@/components/Footer.vue'
    import Product from '@/components/elements/Product.vue'
    import PageContent from '@/mixins/pageContent'
    import IntroTextSection from '@/components/sections/IntroTextSection.vue'

    export default {
        name: 'CollectionPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageContent],
        components: {
            Container,
            GlobalAlert,
            ImageMasthead,
            TextMasthead,
            Footer,
            Product,
            IntroTextSection
        },
        data() {
            return {
                productGroup: null,
                loaded: false,
            }
        },
        computed: {

        },
        methods: {
            loadProductGroup: function () {
                this.loaded = false
                const typeSlug = this.$route.params.slug;

                this.sdk.getProductGroupBySlug(typeSlug)
                    .then(response => {
                        this.productGroup = response.product_groups[0]
                        this.loaded = true
                        this.setPageContentMeta(this.store.name, this.productGroup.title + ' Collection')
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
        },
        created() {
            this.loadProductGroup()
        }
    }
</script>