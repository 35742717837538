<template>
    <Accordion v-for="(item, index) in items" :key="index" :title="item.title">
        <span v-html="item.content"></span>
    </Accordion>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import Accordion from '@/components/elements/Accordion.vue'

    export default {
        name: 'AccordionList',
        mixins: [ComponentTheme],
        components: {
            Accordion
        },
        props: {
            items: Array
        }
    }
</script>