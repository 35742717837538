<template>
    <section v-for="(panel, index) in panels" :key="index" :class="'bg-' + panel.background_colour + ' text-' + panel.text_colour">
        <ContainerFluid>
            <div class="grid grid-cols-12 h-screen lg:h-auto">
                <div class="col-span-12 lg:col-span-6 bg-cover bg-top h-full" :class="{'lg:order-2': index % 2 !== 0}" :data-aos="index % 2 ? 'fade-left' : 'fade-right'" :style="{backgroundImage: `url('${panel.image.url}')`}">
                </div>
                <div class="col-span-12 lg:col-span-6 self-center py-4 px-4 lg:py-64 lg:px-32 text-center" :data-aos="index % 2 ? 'fade-right' : 'fade-left'">
                    <h2 class="text-center mb-4 lg:mb-10">{{ panel.title }}</h2>
                    <span class="block mb-4 lg:mb-10" v-html="panel.content"></span>
                    <LinkButton v-if="panel.link_url" :href="panel.link_url" :text-colour="panel.text_colour" class="border-none">{{ panel.link_text }}</LinkButton>
                </div>
            </div>
        </ContainerFluid>
    </section>
</template>

<script>
    import ContainerFluid from '@/components/ContainerFluid'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'ContentPanelSection',
        components: {ContainerFluid, LinkButton},
        props: {
            panels: Array
        }
    }
</script>