<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <TextMasthead title="Create an account or sign in" :small-heading="true" :class="'border-b border-gray'"/>
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 border-gray border-b mb-16 pb-16 lg:mb-0 lg:pb-0 lg:border-b-0 lg:col-span-6 lg:pr-32 lg:border-gray lg:border-r"
                                 data-aos="fade-up">
                                <h3 class="mb-8">Create an account</h3>
                                <template v-if="createUserSuccess">
                                    <p class="text-xl">Your account has been successfully created.</p>
                                    <p>Check your emails to activate your account.</p>
                                </template>
                                <template v-else>
                                    <Alert v-if="createUserErrors.length > 0" :messages="createUserErrors"/>
                                    <form v-on:submit.prevent="createUser($event)">
                                        <div class="mb-4 flex justify-between space-x-4">
                                            <label class="w-1/2">
                                                <span class="text-gray-700">First Name*</span>
                                                <input name="first_name" type="text" class="w-full" required/>
                                            </label>
                                            <label class="w-1/2">
                                                <span class="text-gray-700">Last Name*</span>
                                                <input name="last_name" type="text" class="w-full" required/>
                                            </label>
                                        </div>
                                        <div class="mb-4">
                                            <label>
                                                <span class="text-gray-700">Email address*</span>
                                                <input name="email" type="email" class="w-full" required/>
                                            </label>
                                        </div>
                                        <div class="mb-4">
                                            <label>
                                                <span class="text-gray-700">Password*</span>
                                                <input name="password" type="password" class="w-full" required/>
                                            </label>
                                        </div>
                                        <div class="mb-4">
                                            <label>
                                                <span class="text-gray-700">Confirm Password*</span>
                                                <input name="confirm_password" type="password" class="w-full" required/>
                                            </label>
                                        </div>
                                        <!--<div class="mb-4">
                                            <label class="inline-flex items-center">
                                                <input type="checkbox" class="" checked="">
                                                <span class="ml-2">I agree to the terms and conditions.</span>
                                            </label>
                                        </div>-->
                                        <Button :class="'mt-6'">Register</Button>
                                    </form>
                                </template>
                            </div>
                            <div class="col-span-12 lg:col-span-6 lg:pl-32" data-aos="fade-up" data-aos-delay="300">
                                <h3 class="mb-8">I have an account</h3>
                                <Alert v-if="loginErrors.length > 0" :messages="loginErrors"/>
                                <form v-on:submit.prevent="login($event)">
                                    <div class="mb-4">
                                        <label>
                                            <span class="text-gray-700">Email address*</span>
                                            <input name="email" type="email" class="w-full" required/>
                                        </label>
                                    </div>
                                    <div class="mb-4">
                                        <label class="block">
                                            <span class="text-gray-700">Password*</span>
                                            <input name="password" type="password" class="w-full" required/>
                                        </label>
                                    </div>
                                    <p>
                                        <router-link :to="{name: 'password-reset'}" title="Link: Password Reset">
                                            Forgotten password?
                                        </router-link>
                                    </p>
                                    <Button :class="'mt-6'">Login</Button>
                                </form>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import Container from '@/components/Container'
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Alert from '@/components/elements/Alert.vue'
    import Button from '@/components/elements/Button.vue'
    import Footer from '@/components/Footer.vue'

    export default {
        name: 'LoginPage',
        inject: ['dataStore', 'sdk'],
        components: {
            Container,
            GlobalAlert,
            TextMasthead,
            Alert,
            Button,
            Footer
        },
        data() {
            return {
                loaded: false,
                loginErrors: [],
                createUserErrors: [],
                createUserSuccess: false
            };
        },
        methods: {
            createUser: function (event) {
                this.createUserErrors = [];
                this.createUserSuccess = false;

                if(
                    event.target.elements.password.value !==
                    event.target.elements.confirm_password.value
                ){
                    this.createUserErrors = [
                        'Passwords must match'
                    ];
                    return;
                }

                const user = {
                    email: event.target.elements.email.value,
                    first_name: event.target.elements.first_name.value,
                    last_name: event.target.elements.last_name.value,
                    password: event.target.elements.password.value
                }

                this.sdk.createUser(user).then(response => {
                    console.debug(response)
                    this.createUserSuccess = true;
                }).catch(error => {
                    if ('errors' in error.response.data) {
                        this.createUserErrors = [
                            error.response.data.errors[0].message
                        ];
                    } else {
                        this.createUserErrors = [
                            'Error processing your request'
                        ];
                    }
                })
            },
            login: function (event) {
                this.loginErrors = [];
                const email = event.target.elements.email.value
                const password = event.target.elements.password.value

                this.sdk.login(email, password).then(response => {
                    this.dataStore.setUser(response.user);
                    this.$router.push({name: 'account'});
                }).catch(error => {
                    if ('errors' in error.response.data) {
                        this.loginErrors = [
                            error.response.data.errors[0].message
                        ];
                    } else {
                        this.loginErrors = [
                            'Error processing your request'
                        ];
                    }
                })
            }
        },
        created() {
            this.loaded = true
        }
    }
</script>