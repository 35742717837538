<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <TextMasthead title="Order #123" :small-heading="true" :class="'border-b border-gray'" />
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="grid grid-cols-12 gap-4 lg:gap-16">
                            <div class="mb-8 col-span-12 lg:mb-0 lg:col-span-3" data-aos="fade-right">
                                <AccountNavigation/>
                            </div>
                            <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                                <table class="w-full">
                                    <tr class="border-black border-b">
                                        <th class="text-left font-light py-4">Product</th>
                                        <th class="hidden text-left font-light py-4 lg:block">Price</th>
                                        <th class="text-center font-light py-4">Quantity</th>
                                        <th class="text-right font-light py-4">Total</th>
                                    </tr>
                                    <tr v-for="index in 4" :key="index" class="align-top">
                                        <td class="py-4">
                                            <div class="flex flex-col lg:flex-row">
                                                <img class="w-full lg:max-w-xs lg:mr-4"
                                                     src="http://bedgiants.co.uk/wp-content/uploads/2018/08/PFX-UD-DIAMOND-JUBILEE-TH.jpg"
                                                     alt=""/>
                                                <div>
                                                    <h4 class="my-2 lg:mt-0">Product 1</h4>
                                                    <p class="mb-0">Product 1</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="hidden py-4 lg:block">
                                            <p class="text-xl">£200</p>
                                        </td>
                                        <td class="py-4">
                            <span class="flex items-center justify-center">
                                <p>x1</p>
                            </span>
                                        </td>
                                        <td class="py-4 text-right">
                                            <p class="font-bold mr-1 text-xl">£200</p>
                                        </td>
                                    </tr>
                                </table>
                                <div class="border-black border-t py-16">
                                    <div class="ml-auto lg:w-1/3">
                                        <p class="flex justify-between text-2xl mb-2">
                                            <span>Subtotal</span><span>£100</span></p>
                                        <p class="flex justify-between text-2xl mb-2">
                                            <span>Discount</span><span>-£0</span></p>
                                        <p class="flex justify-between text-3xl font-bold">
                                            <span>Total</span><span>£100</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from '@/components/AccountNavigation.vue'
    import Footer from '@/components/Footer.vue'
    import Container from '@/components/Container'

    export default {
        name: 'AccountOrderPage',
        inject: ['dataStore'],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            Footer
        },
        data() {
            return {
                loaded: false
            };
        },
        created() {
            this.loaded = true
        }
    }
</script>