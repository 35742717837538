<template>
    <div class="overflow-hidden">
        <template v-for="(content, index) in pageContent.content" :key="index">
            <template v-if="!content.content_type">
                <HtmlSection class="html-section" :content="content.value"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'accordion_section'">
                <AccordionSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :title="content.value.title" :items="content.value.items"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'related_product_section'">
                <RelatedProductSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :title="content.value.title" :link-url="content.value.link_url"
                                       :link-text="content.value.link_text" :products="content.value.products"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'tagged_product_section'">
                <TaggedProductSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :title="content.value.title" :link-url="content.value.link_url"
                                      :link-text="content.value.link_text" :tag-slug="content.value.tag" :alignment="content.value.alignment"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'product_group_section'">
                <ProductGroupSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :product-group-slug="content.value.group"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'product_group_section_with_image'">
                <ProductGroupSectionWithImage :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :product-group-slug="content.value.group" :image-flip="content.value.image_flip" />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'quote_section'">
                <QuoteSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :quote="content.value.quote" :author="content.value.author" :image="content.value.image.url"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'content_panel_section'">
                <ContentPanelSection :panels="content.value.panels"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'image_banner_section'">
                <ImageBannerSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :title="content.value.title" :text="content.value.text" :link-url="content.value.link_url"
                                    :image="content.value.image.url" :text-style="content.value.text_style"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'image_grid_section'">
                <ImageGridSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :title="content.value.title" :panels="content.value.panels" />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'content_grid_section'">
                <ContentGridSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :title="content.value.title" :panels="content.value.panels"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'intro_text_section'">
                <IntroTextSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :text="content.value.text" :link-url="content.value.link_url"
                                  :link-text="content.value.link_text" />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'contact_section'">
                <ContactSection :background-colour="content.value.background_colour" :text-colour="content.value.text_colour" :content="content.value" />
            </template>
        </template>
    </div>
</template>

<script>
    import AccordionSection from '@/components/sections/AccordionSection.vue'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'
    import TaggedProductSection from '@/components/sections/TaggedProductSection.vue'
    import ProductGroupSection from '@/components/sections/ProductGroupSection.vue'
    import ProductGroupSectionWithImage from '@/components/sections/ProductGroupSectionWithImage.vue'
    import QuoteSection from '@/components/sections/QuoteSection.vue'
    import ContentPanelSection from '@/components/sections/ContentPanelSection.vue'
    import ImageBannerSection from '@/components/sections/ImageBannerSection.vue'
    import ImageGridSection from '@/components/sections/ImageGridSection.vue'
    import IntroTextSection from '@/components/sections/IntroTextSection.vue'
    import ContentGridSection from '@/components/sections/ContentGridSection.vue'
    import ContactSection from '@/components/sections/ContactSection.vue'
    import HtmlSection from '@/components/sections/HtmlSection.vue'

    export default {
        name: 'Masthead',
        components: {
            AccordionSection,
            RelatedProductSection,
            TaggedProductSection,
            ProductGroupSection,
            ProductGroupSectionWithImage,
            QuoteSection,
            ContentPanelSection,
            ImageBannerSection,
            IntroTextSection,
            ImageGridSection,
            ContentGridSection,
            ContactSection,
            HtmlSection
        },
        props: {
            pageContent: Object,
        }
    }
</script>



