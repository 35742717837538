<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <TextMasthead title="Your Addresses" :small-heading="true" :class="'border-b border-gray'" />
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="grid grid-cols-12 gap-4 lg:gap-16">
                            <div class="mb-8 col-span-12 lg:mb-0 lg:col-span-3" data-aos="fade-right">
                                <AccountNavigation/>
                            </div>
                            <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                                <Alert v-if="updateUserErrors.length > 0" :messages="updateUserErrors"/>
                                <Alert v-if="updateUserSuccess" :messages="['Details successfully updated']" :theme="'success' "/>
                                <form v-on:submit.prevent="updateUser">
                                    <div class="grid grid-cols-12 gap-4 lg:gap-8">
                                        <div class="col-span-12 lg:col-span-6">
                                            <h3>Billing Address</h3>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Address Line 1*</span>
                                                    <input name="billing_address[address_line_1]" type="text" class="w-full" v-model="billingAddress.address_line_1" required />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Address Line 2</span>
                                                    <input name="billing_address[address_text]" type="text" v-model="billingAddress.address_text" class="w-full" />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">City*</span>
                                                    <input name="billing_address[city]" type="text" class="w-full" v-model="billingAddress.city" required />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Region</span>
                                                    <input name="billing_address[region]" type="text" v-model="billingAddress.region" class="w-full" />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Postcode*</span>
                                                    <input name="billing_address[postcode]" type="text" class="w-full" v-model="billingAddress.postcode" required />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-span-12 lg:col-span-6">
                                            <h3>Shipping Address</h3>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Address Line 1*</span>
                                                    <input name="shipping_address[address_line_1]" type="text" class="w-full" v-model="shippingAddress.address_line_1" required />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Address Line 2</span>
                                                    <input name="shipping_address[address_text]" type="text" v-model="shippingAddress.address_text" class="w-full" />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">City*</span>
                                                    <input name="shipping_address[city]" type="text" class="w-full" v-model="shippingAddress.city" required />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Region</span>
                                                    <input name="shipping_address[region]" type="text" v-model="shippingAddress.region" class="w-full" />
                                                </label>
                                            </div>
                                            <div class="mb-4">
                                                <label>
                                                    <span class="text-gray-700">Postcode*</span>
                                                    <input name="shipping_address[postcode]" type="text" class="w-full" v-model="shippingAddress.postcode" required />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <Button :class="'mt-6 mb-16'">Update</Button>
                                </form>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import Container from '@/components/Container'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from '@/components/AccountNavigation.vue'
    import Alert from '@/components/elements/Alert.vue';
    import Button from '@/components/elements/Button.vue'
    import Footer from '@/components/Footer.vue'

    export default {
        name: 'AccountAddressPage',
        inject: ['dataStore', 'sdk'],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            Alert,
            Button,
            Footer
        },
        data() {
            return {
                loaded: false,
                updateUserErrors: [],
                updateUserSuccess: false,
                shippingAddress: {
                    address_line_1: null,
                    address_text: null,
                    city: null,
                    region: null,
                    postcode: null,
                    country_id: 826,
                },
                billingAddress: {
                    address_line_1: null,
                    address_text: null,
                    city: null,
                    region: null,
                    postcode: null,
                    country_id: 826,
                }
            };
        },
        computed: {
            user: function () {
                return this.dataStore.getUser()
            }
        },
        methods: {
            updateUser: function () {
                this.updateUserErrors = [];
                this.updateUserSuccess = false;

                const userData = {
                    billing_address: this.billingAddress,
                    shipping_address: this.shippingAddress,
                }

                this.sdk.updateUser(this.user.id, userData).then(response => {
                    this.dataStore.setUser(response.user)
                    this.updateUserSuccess = true;
                }).catch(error => {
                    if ('errors' in error.response.data) {
                        this.updateUserErrors = [
                            error.response.data.errors[0].message
                        ];
                    } else {
                        this.updateUserErrors = [
                            'Error processing your request'
                        ];
                    }
                })
            },
        },
        created() {
            if(this.user.customer && this.user.customer.billing_address){
                this.billingAddress = this.user.customer.billing_address
            }

            if(this.user.customer && this.user.customer.shipping_address){
                this.shippingAddress = this.user.customer.shipping_address
            }

            this.loaded = true
        }
    }
</script>