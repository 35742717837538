<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <TextMasthead title="Your Account" :small-heading="true" :class="'border-b border-gray'" />
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="grid grid-cols-12 gap-8 lg:gap-16">
                            <div class="col-span-12 lg:col-span-3" data-aos="fade-right">
                                <AccountNavigation/>
                            </div>
                            <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                                <p class="text-xl">Welcome <span class="font-bold">{{ user.email }}</span> to your account.</p>
                                <p>You can review your orders and edit your details here.</p>
                            </div>
                        </div>
                    </Container>
                </section>
                <RelatedProductSection title="Products recommended for you" :products="[]"/>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import Container from '@/components/Container'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from '@/components/AccountNavigation.vue'
    import Footer from '@/components/Footer.vue'
    import RelatedProductSection from "@/components/sections/RelatedProductSection";

    export default {
        name: 'AccountPage',
        inject: ['dataStore'],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            RelatedProductSection,
            Footer
        },
        data() {
            return {
                loaded: false
            };
        },
        computed: {
            user: function () {
                return this.dataStore.getUser()
            }
        },
        created() {
            this.loaded = true
        }
    }
</script>