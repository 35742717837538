<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <TextMasthead title="Collections" background-colour="white" :center-text="true" />
            <main>
                <ProductGroupSection v-for="(productGroup, index) in productGroups" :key="index" :product-group="productGroup"
                                     :background-colour="index % 2 === 0 ? 'white' : 'transparent'" />
                <template v-if="this.loadingProducts === true">
                    <div class="relative h-24 bg-white">
                        <Loading/>
                    </div>
                </template>
                <div v-if="showLoadMore" class="py-6 lg:py-8 bg-white">
                    <Button @click.prevent="loadMoreProductGroups" border-colour="black"
                            :class="'border-black mt-2 mx-auto w-full block lg:max-w-xl'">{{ this.loadingProducts ? 'Loading...' : 'Load more' }}</Button>
                </div>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Footer from '@/components/Footer.vue'
    import PageContent from '@/mixins/pageContent'
    import ProductGroupSection from '@/components/sections/ProductGroupSection.vue'
    import Button from '@/components/elements/Button.vue'
    import Loading from "../components/Loading";

    export default {
        name: 'CollectionListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageContent],
        components: {
            GlobalAlert,
            TextMasthead,
            Footer,
            Loading,
            ProductGroupSection,
            Button
        },
        data() {
            return {
                page: 1,
                pagination_interval: 4,
                productGroups: null,
                loaded: false,
                showLoadMore: false,
                loadingProducts: false
            }
        },
        computed: {
            pagination: function () {
                return {
                    page: this.page,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            loadMoreProductGroups: function () {
                this.page++
                this.loadProductGroups(true, this.pagination);
            },
            loadProductGroups: function (append = false, pagination = {page: 1}) {
                this.loadingProducts = true
                if (!append) {
                    this.products = [];
                    this.showLoadMore = false
                }

                this.sdk.getProductGroups(pagination, [], [], ['*'])
                    .then(response => {
                        this.productGroups = response.product_groups
                        this.loaded = true

                        if (append) {
                            this.productGroups = [...this.productGroups, ...response.product_groups]
                        } else {
                            this.productGroups = response.product_groups
                        }

                        this.showLoadMore = response.current_page !== response.last_page

                        this.setPageContentMeta(this.store.name, 'Collections')
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                }) .finally(() => {
                    this.loadingProducts = false
                });
            },
        },
        created() {
            this.loadProductGroups(false, this.pagination)
        }
    }
</script>