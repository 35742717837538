<template>
    <section :class="componentThemeClass">
        <Container container-class="px-4 py-16 lg:py-32">
            <h2 v-if="title" class="h3 mb-8" data-aos="fade-right">{{ title }}</h2>
            <div class="grid grid-cols-12 gap-4">
                <div v-for="(panel, index) in panels" :key="index" class="col-span-12 bg-cover" :class="[panel.column_span ? 'lg:col-span-' + panel.column_span : 'lg:col-span-4']" data-aos="fade-in" :data-aos-delay="index * 50" :style="{backgroundImage: `url('${panel.image.url}')`, height: '450px'}">
                    <a :href="panel.link_url" :title="'Link: ' + panel.title">
                        <template v-if="panel.sub_title">
                            <div class="flex flex-col w-full h-full p-5 justify-end" :class="[panel.text_colour ? 'text-' + panel.text_colour : '']">
                                <h3 class="h4 mb-0">{{ panel.title }}</h3>
                                <span class="mt-1 text-sm" v-html="panel.sub_title"></span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex flex-col w-full h-full p-5 justify-center items-center" :class="[panel.text_colour ? 'text-' + panel.text_colour : '']">
                                <h3 class="mb-0">{{ panel.title }}</h3>
                            </div>
                        </template>
                    </a>
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'ContentGridSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            title: String,
            link_url: String,
            panels: Array
        }
    }
</script>