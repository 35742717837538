<template>
    <section :class="componentThemeClass">
        <Container container-class="px-4 py-16 lg:py-32 lg:max-w-4xl">
            <h2 v-if="title" class="mb-16 text-center" data-aos="fade-right">{{ title }}</h2>
            <AccordionList :background-colour="_backgroundColour" :text-colour="_textColour" :items="items" data-aos="fade-in" />
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import AccordionList from '@/components/elements/AccordionList.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'AccordionSection',
        mixins: [ComponentTheme],
        components: {Container, AccordionList},
        props: {
            title: String,
            items: String
        }
    }
</script>