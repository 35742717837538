<template>
    <section v-if="group && group.products.length > 0" :class="componentThemeClass">
        <Container container-class="px-4 py-16 lg:py-32">
            <div class="flex justify-between items-center mb-8 lg:mb-12">
                <h2 class="mb-0" data-aos="fade-right">{{ group.title }}</h2>
                <LinkButton :href="$router.resolve({name: 'collection', params: { slug: group.slug }}).href" :title="'Link: ' + group.title"
                            :text-colour="_textColour" background-colour="transparent" border-colour="transparent" class="text-xl" data-aos="fade-left">View collection</LinkButton>
            </div>
            <div class="grid grid-flow-col auto-cols-3/4 pb-8 gap-4 lg:auto-cols-1/4" :class="[_backgroundColour === 'white' ? 'scroll-bar-dark' : 'scroll-bar']">
                <div v-for="(product, index) in group.products" :key="index" data-aos="fade-in" :data-aos-delay="index * 50">
                    <Product :product="product" />
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import Product from '@/components/elements/Product.vue'
    import ComponentTheme from '@/mixins/componentTheme'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'ProductGroupSection',
        inject: ['sdk', 'errorHandler'],
        mixins: [ComponentTheme],
        components: {
            Product,
            Container,
            LinkButton
        },
        props: {
            productGroupSlug: String,
            productGroup: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                group: null
            }
        },
        methods: {
            loadProductGroup: function () {
                this.sdk.getProductGroupBySlug(this.productGroupSlug)
                    .then(response => {
                        this.group = response.product_groups[0]
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
        },
        created() {
            if (this.productGroup) {
                this.group = this.productGroup
            } else {
                this.loadProductGroup()
            }
        }
    }
</script>