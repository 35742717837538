<template>
    <section class="relative bg-cover bg-center flex items-center h-600px" :class="componentThemeClass" :style="image ? {backgroundImage: `url('${image}')`} : {}">
        <template v-if="title">
            <Container container-class="px-4 text-center lg:max-w-4xl">
                    <h2 class="p-4 mb-16" data-aos="fade-right">
                        {{ title }}
                    </h2>
                    <div v-if="text">
                        <LinkButton v-if="linkUrl && textStyle === 'button'" :link="linkUrl" background-colour="white" text-colour="black" class="lg:text-xl px-8 py-4 mt-4" data-aos="fade-up">
                            {{ text }}
                        </LinkButton>
                        <Button v-else-if="textStyle === 'button'" class="lg:text-2xl" data-aos="fade-up">
                            {{ text }}
                        </Button>
                        <p v-else class="lg:text-2xl text-white" data-aos="fade-up">
                            <a v-if="linkUrl" :href="linkUrl" :title="'Link: ' + title">{{ text }}</a>
                            <span>{{ text }}</span>
                        </p>
                    </div>
            </Container>
        </template>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'
    import LinkButton from "../elements/LinkButton";
    import Button from "../elements/Button";

    export default {
        name: 'ImageBannerSection',
        mixins: [ComponentTheme],
        components: {Button, LinkButton, Container},
        props: {
            title: String,
            linkUrl: String,
            text: String,
            image: String,
            textStyle: String
        },
        created() {
            if(this.image){
                this._backgroundColour = null
            }
        }
    }
</script>