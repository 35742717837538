<template>
    <section>
        <Container container-class="px-4 py-16 lg:py-32 lg:max-w-4xl" animation="fade-in">
            <span v-html="content"></span>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'

    export default {
        name: 'HtmlSection',
        components: {Container},
        props: {
            content: String
        }
    }
</script>