export default {
    data() {
        return {
            pageContent: null,
            redirect404: false
        };
    },
    computed: {
        store: function () {
            return this.dataStore.state.store
        },
        pageSlug: function () {
            return this.$route.params.slug;
        }
    },
    methods: {
        async loadPageContent () {
            const slug = this.pageSlug

            this.sdk.getPageBySlug(slug)
                .then(response => {
                    if (response.pages.length === 0 && this.redirect404) {
                        this.$router.push({name: '404'});
                        return;
                    }

                    if(response.pages.length > 0) {
                        this.pageContent = response.pages[0];

                        this.pageContent.content.forEach(content => {
                            if(content.content_type) {
                                try {
                                    const jsonContent = JSON.parse(content.value);
                                    content.value = jsonContent
                                } catch (e) {
                                    console.debug(e)
                                }
                            }
                        })

                        this.setPageContentMeta(this.store.name, this.pageContent.title, this.pageContent.description)
                    }
                }).catch(error => {
                console.log(error);
            })
        },
        setPageContentMeta: function (store, title, description = null) {
            document.title = store + ' | ' + title

            if(description) {
                document.getElementsByTagName('meta')["description"].content = description
            }
        },
        getPageContentByKey: function (key) {
            const content = this.pageContent.content.filter(content => content.content_type && content.content_type.key === key)

            if(content.length > 0){
                return content[0]
            }

            return null;
        }
    }
}