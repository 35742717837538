<template>
    <section :class="componentThemeClass">
        <Container container-class="px-4 py-16 lg:py-32 lg:max-w-4xl">
            <div class="grid grid-cols-12 gap-4">
                <div v-if="content" class="col-span-12 lg:col-span-6 text-center" data-aos="fade-right">
                    <span v-html="content"></span>
                </div>
                <div class="col-span-12 lg:col-span-6 text-center items-center" data-aos="fade-left">
                    <template v-if="store.contact_email">
                        <h4 class="mb-2">Email</h4>
                        <p class="mb-8"><a :href="'mailto:' + store.contact_email" :title="'Email: ' + store.name">{{
                            store.contact_email }}</a></p>
                    </template>
                    <template v-if="store.telephone_number">
                        <h4 class="mb-2">Phone</h4>
                        <p>{{ store.telephone_number }}</p>
                    </template>
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'
    export default {
        name: 'ContactSection',
        inject: ['dataStore'],
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            content: String
        },
        computed: {
            store: function () {
                return this.dataStore.state.store
            }
        }
    }
</script>