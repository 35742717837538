<template>
    <template v-if="!loaded">
        <div class="relative h-screen">
            <Loading/>
        </div>
    </template>

    <template v-if="loaded">
        <router-view/>
    </template>
</template>

<script>
    import Loading from "@/components/Loading";
    import dataStore from '@/store/data'
    import AOS from 'aos'
    import axios from 'axios'

    export default {
        name: 'App',
        props: {
            sdk: null,
            errorHandler: null
        },
        components: {Loading},
        data() {
            return {
                loaded: false
            }
        },
        methods: {
            async generateToken(){
                return this.sdk.generateToken()
                    .then(response => {
                        const token = response.token;
                        dataStore.setToken(token);
                        this.loadComponentData().then(() => {
                            this.loaded = true
                        })
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    })
            },
            async loadToken() {
                const apiToken = dataStore.getApiToken()

                if (apiToken) {
                    return this.sdk.getTokenByToken(apiToken)
                        .then(response => {
                            const token = response.token;
                            dataStore.setToken(token);
                            this.loadComponentData().then(() => {
                                this.loaded = true
                            })
                        }).catch(error => {
                            if (error.response.status === 404) {
                                this.generateToken();
                            }
                            this.errorHandler.handleError(error);
                        })
                }

                return this.generateToken()
            },
            async loadStore () {
                this.sdk.getStore().then(response => {
                    const store = response.store
                    dataStore.setStore(store);
                }).catch(error => {
                    this.errorHandler.handleError(error);
                });
            },
            async loadBasket () {
                const basketReference = dataStore.getBasketReference();

                if(basketReference){
                    return this.sdk.getBasketById(basketReference)
                        .then(response => {
                            const basket = response.basket;
                            dataStore.setBasket(basket);
                        }).catch(error => {
                            this.errorHandler.handleError(error);
                        })
                }
            },
            async loadSite () {
                return this.sdk.getSiteBySlug('website').then(response => {
                    const site = response.sites[0]
                    // Convert all the content to objects for ease
                    site.content.forEach(content => {
                        content.value = JSON.parse(content.value)
                    })
                    dataStore.setSite(site);
                    this.setupTheme(site)
                }).catch(error => {
                    this.errorHandler.handleError(error);
                });
            },
            setupTheme: function(site){
                let content = site.content.filter(content => content.content_type && content.content_type.key === 'site_settings')
                let theme = null

                if(content.length > 0) {
                    theme = content[0].value
                }

                document.title = site.title
                document.getElementsByTagName('meta')["description"].content = site.description

                // Append favicon
                let favicon = document.createElement('link')
                favicon.href = theme.theme_favicon_url
                favicon.rel = 'icon'
                document.head.appendChild(favicon)

                // Append font and styles
                let font = document.createElement('link')
                font.href = theme.theme_font_url
                font.rel = 'stylesheet'
                document.head.appendChild(font)

                let cssString = ":root { " +
                    "--theme-body-font-family: {{theme_body_font_family}}; " +
                    "--theme-heading-font-family: {{theme_heading_font_family}}; " +
                    "--theme-body-background-colour: {{theme_body_background_colour}}; " +
                    "--theme-colour-black: {{theme_colour_black}};" +
                    "--theme-colour-primary: {{theme_colour_primary}};" +
                    "--theme-colour-secondary: {{theme_colour_secondary}};" +
                    "--theme-colour-tertiary: {{theme_colour_tertiary}};" +
                    "--theme-border-radius: {{theme_border_radius}};" +
                    "--theme-heading-1-font-weight: {{theme_heading_1_font_weight}};" +
                    "--theme-heading-2-font-weight: {{theme_heading_2_font_weight}};" +
                    "--theme-heading-3-font-weight: {{theme_heading_3_font_weight}};" +
                    "}";
                let styles = document.createElement('style')
                for (const [key, value] of Object.entries(theme)) {
                    cssString = cssString.replace('{{' + key + '}}', value)
                }
                styles.innerText = cssString
                document.head.appendChild(styles)

                document.body.classList.add(theme.theme_contrast)
            },
            async loadComponentData(){
                return await axios.all([this.loadStore(), this.loadSite(), this.loadBasket()])
            },
        },
        created() {
            AOS.init({
                duration: 750,
                easing: 'ease-in-out-sine',
                once: true,
                offset: 60,
            });

            this.loadToken()
        }
    }
</script>