<template>
    <a :href="$router.resolve({name: 'product', params: { slug: product.slug }}).href" :title="'Link: ' + product.title">
        <div class="flex flex-col mb-5">
            <div v-if="product.thumbnail" class="relative overflow-hidden max-h-96 lg:h-96">
                <img :src="product.thumbnail.url" :alt="'Image: ' + product.title" class="left-0 right-0 top-0 bottom-0 max-h-full max-w-full m-auto lg:absolute" />
            </div>
            <div>
                <h4 class="h5 mt-2 mb-1 text-center">{{ product.title }}</h4>
            </div>
            <div class="text-center">
                <span class="font-medium mr-1" :class="{'text-primary' : product.discount_price !== product.price}">{{ product.discount_price !== product.price ? moneyFormat(product.discount_price) : moneyFormat(product.price) }}</span>
                <span class="font-light line-through" v-if="product.discount_price !== product.price">{{ moneyFormat(product.price) }}</span>
            </div>
        </div>
    </a>
</template>

<script>
    import MoneyFormat from '@/mixins/moneyFormat'

    export default {
        name: 'Product',
        mixins: [MoneyFormat],
        props: {
            product: Object
        }
    }
</script>