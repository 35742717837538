<template>
    <div v-if="enabled" class="relative py-3 filter drop-shadow-md z-10" :class="componentThemeClass" role="alert">
        <Container container-class="pl-4 pr-10 text-center">
            <span class="font-medium">{{message}}</span>
            <a @click.prevent="enabled = false">
                <XIcon class="absolute top-1/2 right-3 h-6 w-6 -mt-3" />
            </a>
        </Container>
    </div>
</template>

<script>
    import Container from '@/components/Container'
    import SiteContent from '@/mixins/siteContent'
    import ComponentTheme from '@/mixins/componentTheme'
    import { XIcon } from '@heroicons/vue/solid'

    export default {
        name: 'GlobalAlert',
        inject: ['dataStore'],
        mixins: [ComponentTheme, SiteContent],
        components: {
            Container, XIcon
        },
        data() {
            return {
                message: null,
                enabled: false
            }
        },
        created() {
            const alert = this.getSiteContentByKey('global_alert');

            if(alert){
                this._backgroundColour = alert.value.background_colour
                this._textColour = alert.value.text_colour
                this.message = alert.value.message
                this.enabled = alert.value.enabled
            }
        }
    }
</script>



