<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <header :class="componentThemeClass">
                <Navigation :background-colour="_backgroundColour" :text-colour="_textColour" />
                <Container container-class="text-center px-4 py-16 lg:max-w-4xl">
                    <h1 class="h3" data-aos="fade-right">Search results</h1>
                    <p class="text-4xl mb-0" data-aos="fade-up">We found {{ products.length }} products for
                        <input type="text" v-model="query" @keyup.prevent="searchChange()"
                               class="block font-bold m-auto text-center mt-3 border-0 border-b border-white bg-transparent text-4xl italic focus:ring-0" />
                    </p>
                </Container>
            </header>
            <main>
                <section class="bg-white">
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="min-h-24">
                            <div v-if="products && products.length > 0"
                                 class="grid grid-cols-12 gap-4 mb-8 lg:mb-16">
                                <div v-for="(product, index) in products" :key="index" class="col-span-12 lg:col-span-3" data-aos="fade-in" :data-aos-delay="index">
                                    <Product :product="product" />
                                </div>
                            </div>
                            <div v-if="!this.loadingProducts && products.length === 0">
                                <p class="text-2xl mb-0 h-24 flex justify-center items-center">
                                    <span>No results found</span>
                                </p>
                            </div>
                            <template v-if="this.loadingProducts === true">
                                <div class="relative h-24">
                                    <Loading/>
                                </div>
                            </template>
                            <div v-if="showLoadMore" class="mt-6 lg:mt-8" data-aos="fade-up">
                                <span v-if="this.products.length > 0" class="text-sm text-center block">Showing {{ this.products.length }} products</span>
                                <Button @click.prevent="loadMoreProducts" :class="'mt-2 mx-auto w-full block lg:max-w-xl'">{{ this.loadingProducts ? 'Loading...' : 'Load more' }}</Button>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import Navigation from '@/components/Navigation.vue'
    import Footer from '@/components/Footer.vue'
    import Product from '@/components/elements/Product.vue'
    import Button from '@/components/elements/Button.vue'
    import Loading from "../components/Loading";
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'SearchPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [ComponentTheme],
        components: {
            Container,
            GlobalAlert,
            Loading,
            Navigation,
            Footer,
            Product,
            Button
        },
        data() {
            return {
                query: null,
                page: 1,
                pagination_interval: 16,
                sorting: {
                    property: 'created_at',
                    direction: 'desc'
                },
                sortingOptions: [
                    {
                        property: 'price',
                        direction: 'asc',
                        label: 'Price Low to High'
                    },
                    {
                        property: 'price',
                        direction: 'desc',
                        label: 'Price High to Low'
                    },
                    {
                        property: 'created_at',
                        direction: 'desc',
                        label: 'Newest'
                    }
                ],
                products: [],
                loaded: false,
                showLoadMore: false,
                loadingProducts: false
            };
        },
        computed: {
            pagination: function () {
                return {
                    page: this.page,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            loadMoreProducts: function () {
                this.page++
                this.loadProducts(true, this.pagination, this.filters, [this.sorting]);
            },
            searchChange: function() {
                this.products = [];

                if( this.query &&  this.query.length >= 3 && !this.loadingProducts){
                    this.loadProducts()
                }
            },
            loadProducts: function (append = false, pagination = {page: 1}, filters = [], sort = [], includes = []) {
                this.loadingProducts = true

                if (!append) {
                    this.products = [];
                    this.showLoadMore = false
                }

                if(this.productType) {
                    filters.push({
                        property: 'product_type_id',
                        value: this.productType.id,
                        operator: '='
                    })
                }

                this.sdk.searchProducts(this.query, pagination, filters, sort, includes)
                    .then(response => {
                        if (append) {
                            this.products = [...this.products, ...response.products]
                        } else {
                            this.products = response.products;
                        }

                        this.showLoadMore = response.current_page !== response.last_page
                        this.loaded = true
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                }) .finally(() => {
                    this.loadingProducts = false
                });
            }
        },
        created() {
            this._backgroundColour = 'primary';
            this._textColour = 'white';

            if (this.$route.query.query) {
                this.query = this.$route.query.query
            }

            if(this.query) {
                this.loadProducts();
            }
            else{
                this.loaded = true
            }
        }
    }
</script>