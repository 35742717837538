<template>
    <div class="relative container-fluid mx-auto" :class="containerClass" :data-aos="animation">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Container',
        props: {
            containerClass: String,
            animation: {
                type: String,
                default: ''
            }
        }
    }
</script>