<template>
    <transition name="fade" mode="out-in" data-aos="fade-in" data-aos-duration="500">
        <div v-if="basketOpen" class="filter drop-shadow-lg fixed m-auto bottom-0 left-0 right-0 z-10 max-w-2xl bg-white text-black border border-gray-100 lg:absolute lg:left-auto lg:bottom-auto lg:max-w-auto">
            <div class="p-4">
                <div class="relative mb-4">
                    <h4 class="mb-0 text-primary">Added to bag <CheckIcon class="inline h-6 w-6" /></h4>
                    <a class="absolute top-0 right-0" @click.prevent="basketToggle(false)">
                        <XIcon class="inline h-6 w-6" />
                    </a>
                </div>
                <div class="flex flex-row items-center">
                    <div class="w-1/2 mr-4 lg:mr-8" v-if="basketProduct.product.thumbnail">
                        <img class="w-full lg:max-w-xs" :src="basketProduct.product.thumbnail.url" :alt="'Image: ' + basketProduct.product.title" />
                    </div>
                    <div :class="{'w-1/2': basketProduct.product.thumbnail}">
                        <h4 class="mb-3">{{ basketProduct.product.title }}</h4>
                        <p class="mb-3 flex justify-between lg:justify-start">
                            <span class="medium mr-1" :class="{'text-primary': basketProduct.product.discount_price !== basketProduct.product.price}">{{ moneyFormat(formatBasketProductPrice(basketProduct, basketProduct.quantity)) }}</span>
                            <span class="font-light line-through" v-if="basketProduct.product.discount_price !== basketProduct.product.price">{{ moneyFormat(basketProduct.product.price) }}</span>
                        </p>
                        <p>Qty {{basketProduct.quantity}}</p>
                    </div>
                </div>
                <div class="flex flex-col justify-between mt-4 lg:space-x-4 lg:flex-row">
                    <LinkButton :href="$router.resolve({name: 'basket'}).href" title="Link: Basket" background-colour="transparent" border-colour="black" text-colour="black" class="flex-1 mb-2">View Basket</LinkButton>
                    <LinkButton :href="$router.resolve({name: 'checkout'}).href" title="Link: Checkout" class="flex-1 mb-2">Checkout</LinkButton>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { XIcon, CheckIcon } from '@heroicons/vue/solid'
    import MoneyFormat from '@/mixins/moneyFormat'
    import BasketActions from '@/mixins/basketActions'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'BasketAlert',
        inject: ['sdk', 'dataStore'],
        mixins: [MoneyFormat, BasketActions],
        components: {
            XIcon, CheckIcon, LinkButton
        },
        data() {
            return {
                basketOpen: false,
                basketProduct: null
            }
        },
        computed: {
            basket: function () {
                return this.dataStore.state.basket
            }
        },
        methods: {
            basketToggle: function (open = null) {
                if(open !== null){
                    this.basketOpen = open
                }
                else {
                    this.basketOpen = !this.basketOpen
                }
            },
        },
        created() {
            this.emitter.on('basket-updated', e => {
                this.basketToggle(true)

                this.basket.products.forEach(basketProduct => {
                    if(basketProduct.product.id === e){
                        this.basketProduct = basketProduct
                    }
                })
            })
        }
    }
</script>



