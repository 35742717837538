<template>
    <transition name="fade" mode="out-in">
        <div v-if="product">
            <GlobalAlert />
            <TextMasthead background-colour="white" />
            <main>
                <section ref="product-info" class="bg-white">
                    <Container container-class="px-4 pt-4 pb-16 lg:pb-32">
                        <a @click="$router.go(-1)" title="Link: Previous Page" class="mb-8 block" data-aos="fade-down"><ArrowSmLeftIcon class="inline h-4 w-4" /> Back</a>
                        <div class="grid grid-cols-12 gap-4 lg:gap-16 lg:items-start">
                            <div class="col-span-12 mb-8 lg:col-span-7 lg:mb-0" data-aos="fade-right">
                                <img v-if="selectedProduct.images.length > 1" :src="selectedProduct.images[0].url"
                                     :alt="'Image: ' + selectedProduct.images[0].name" class="block w-full"/>
                                <img v-else-if="selectedProduct.thumbnail" :src="selectedProduct.thumbnail.url"
                                     :alt="'Image: ' + selectedProduct.thumbnail.name" class="block m-auto"/>
                                <img v-else-if="product.images.length > 1" :src="product.images[0].url"
                                     :alt="'Image: ' + product.images[0].name" class="block w-full"/>
                                <img v-else-if="product.thumbnail" :src="product.thumbnail.url"
                                     :alt="'Image: ' + product.thumbnail.name" class="block m-auto"/>
                            </div>
                            <div class="col-span-12 lg:col-span-5 lg:sticky lg:top-0" data-aos="fade-left">
                                <h1 class="h3 mb-8">{{ product.title }}</h1>
                                <p class="h4 mb-8 flex">
                                    <span class="font-medium mr-1" :class="{'text-primary': selectedProduct.discount_price !== selectedProduct.price}">
                                        {{ selectedProduct.discount_price !== selectedProduct.price ? moneyFormat(selectedProduct.discount_price) : moneyFormat(selectedProduct.price) }}
                                    </span>
                                    <span class="font-light line-through" v-if="selectedProduct.discount_price !== selectedProduct.price">{{ moneyFormat(selectedProduct.price) }}</span>
                                </p>
                                <template v-if="productVariants.length > 0">
                                    <h5>Type</h5>
                                    <div class="flex gap-2 mb-8 items-center flex-wrap">
                                        <template v-for="(childProduct, index) in productVariants" :key="index">
                                            <template v-if="childProduct.thumbnail">
                                                <a :title="'Select: ' + childProduct.title" @click.prevent="selectProductVariant(childProduct.id)">
                                                    <img :src="childProduct.thumbnail.url" :alt="'Image Thumbnail: ' + childProduct.title" class="h-auto max-h-24 w-auto lg:max-h-32"
                                                         :class="{'border-2 border-primary': selectedProductId === childProduct.id}" />
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a :title="'Select: ' + childProduct.title" @click.prevent="selectProductVariant(childProduct.id)">
                                                    <span class="inline-block p-3 text-center" :class="[selectedProductId === childProduct.id ? 'border-2 border-primary' : 'border border-black']">{{ childProduct.title }}</span>
                                                </a>
                                            </template>
                                        </template>
                                    </div>
                                </template>

                                <template v-if="product.attributes.length > 0">
                                    <Accordion v-for="(attribute, index) in product.attributes" :key="index" :title="attribute.name">
                                        <div class="py-1" v-for="(values, index) in attribute.values" :key="index">
                                            <strong>{{ values.name }}:</strong> {{ values.value }}
                                        </div>
                                    </Accordion>
                                </template>

                                <AccordionList :items="productContent" />

                                <div class="flex mt-8 lg:mt-12 content-end">
                                    <div v-if="!product.referral_url" class="p-3 flex items-center border border-black mr-3 rounded-theme">
                                        <label class="mr-3" for="quantity">Qty:</label>
                                        <input id="quantity" type="number" v-model="quantity" class="text-center border-0 inline w-8 h-8 p-0" step="1" min="1">
                                    </div>
                                    <LinkButton v-if="product.referral_url" :disabled="!this.selectedProductId" :href="product.referral_url" :title="'Link: ' + product.title" :class="'w-full block'">
                                        Buy Now
                                    </LinkButton>
                                    <Button v-else :disabled="!this.selectedProductId" @click.prevent="addProductToBasket(this.selectedProductId)" :class="'w-full block'">
                                        Add to Bag
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div v-if="product.description" class="text-center max-w-4xl mx-auto font-medium mt-16 lg:mt-32">
                            <p class="text-2xl lg:text-4xl" data-aos="fade-right">{{ product.description }}</p>
                        </div>
                        <div v-if="selectedProduct.images.length > 1" class="flex auto-cols-1/2 scroll-bar-dark gap-4 lg:justify-center lg:auto-cols-1/4 mt-16 lg:mt-32">
                            <template v-for="(image, index) in selectedProduct.images" :key="index">
                                <img v-if="index !== 0" :src="image.url" :alt="'Image Thumbnail: ' + image.name" class="max-h-96 lg:min-h-96 lg:max-h-full lg:h-50vh w-auto mb-4"/>
                            </template>
                        </div>
                    </Container>
                </section>
                <RecentlyViewedProductSection title="Recently viewed" background-colour="primary" text-colour="white" alignment="center" />
                <RelatedProductSection title="We think you'd like" alignment="center" :products="relatedProducts.length > 0 ? relatedProducts : recommendedProducts"/>
                <ContentSections v-if="pageContent" :pageContent="pageContent"/>
                <div v-if="showBuyNowButtons" class="fixed z-10 top-10 mb-10 w-full hidden lg:block" data-aos="fade-in">
                    <Container class="bg-white filter drop-shadow-lg flex items-center justify-between rounded-theme">
                        <div class="p-3 pl-6">
                            <span class="text-2xl">{{ product.title }}</span>
                        </div>
                        <div v-if="productVariants.length > 0" class="p-3">
                            <select class="border-0 text-xl" @change="selectProductVariant($event.target.value)">
                                <option :value="null">Select</option>
                                <option v-for="childProduct in productVariants" :key="childProduct.id"
                                        :value="childProduct.id">{{ childProduct.title }}
                                </option>
                            </select>
                        </div>
                        <div class="p-3">
                            <span class="text-xl">{{ selectedProduct.discount_price !== selectedProduct.price ? moneyFormat(selectedProduct.discount_price) : moneyFormat(selectedProduct.price) }}</span>
                        </div>
                        <div v-if="!product.referral_url" class="p-3 flex content-end">
                            <div class="flex items-center">
                                <label class="text-xl mr-3">Qty:</label>
                                <input type="number" v-model="quantity" class="text-xl text-center border-0 inline w-8 h-8 p-0" step="1" min="1">
                            </div>
                        </div>
                        <div>
                            <LinkButton v-if="product.referral_url" :disabled="!this.selectedProductId"
                                        :href="product.referral_url" :title="'Link: ' + product.title" :class="'py-4 text-xl h-full'">
                                Buy now
                            </LinkButton>
                            <Button v-else :disabled="!this.selectedProductId"
                                    @click.prevent="addProductToBasket(selectedProductId)" :rounded="true" :class="'py-5 text-xl h-full'">
                                Buy now
                            </Button>
                        </div>
                    </Container>
                </div>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import Container from '@/components/Container'
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccordionList from '@/components/elements/AccordionList.vue'
    import LinkButton from '@/components/elements/LinkButton.vue'
    import Button from '@/components/elements/Button.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'
    import RecentlyViewedProductSection from '@/components/sections/RecentlyViewedProductSection.vue'
    import Footer from '@/components/Footer.vue'
    import PageContent from '@/mixins/pageContent'
    import MoneyFormat from '@/mixins/moneyFormat'
    import { ArrowSmLeftIcon } from '@heroicons/vue/solid'
    import Accordion from "../components/elements/Accordion";

    export default {
        name: 'ProductListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageContent, MoneyFormat],
        components: {
            Accordion,
            Container,
            GlobalAlert,
            TextMasthead,
            AccordionList,
            ContentSections,
            RelatedProductSection,
            RecentlyViewedProductSection,
            LinkButton,
            Button,
            Footer,
            ArrowSmLeftIcon
        },
        data() {
            return {
                quantity: 1,
                product: null,
                selectedProductId: null,
                selectedProduct: null,
                relatedProducts: [],
                recommendedProducts: [],
                selectedImageIndex: 0,
                redirect404: false,
                showBuyNowButtons: false
            };
        },
        computed: {
            pageSlug: function () {
                return 'product';
            },
            productSlug: function () {
                return this.$route.params.slug;
            },
            productContent: function () {
                let productContent = [];

                if (this.product.content.length > 0) {
                    productContent = this.product.content.map(content => {
                            return {
                                title: content.name,
                                content: content.value
                            }
                        }
                    );
                }
                return productContent;
            },
            productVariants: function() {
                let products = []

                if(this.product.parent_product){
                    products = this.product.parent_product.child_products;
                }
                else if(this.product.child_products.length > 0 ){
                    products = this.product.child_products;
                }

                return products
            }
        },
        methods: {
            loadProduct: function () {
                const slug = this.productSlug

                this.sdk.getProductBySlug(slug)
                    .then(response => {
                        if (response.products.length === 0 && this.redirect404) {
                            this.$router.push({name: '404'});
                            return;
                        }

                        const product = response.products[0]

                        if(product.parent_product){
                            this.product = product.parent_product;
                            this.selectedProduct = product
                            this.selectedProductId = product.id
                        }
                        else{
                            this.product = product
                            this.selectedProduct = product
                            this.selectedProductId = product.id
                        }

                        // If we have child products the user must select one
                        if(this.selectedProduct.child_products.length > 0){
                            this.selectedProductId = null
                        }

                        this.setPageContentMeta(this.store.name, this.product.title, this.product.description)

                        if(this.product.related_products){
                            this.relatedProducts = this.product.related_products
                        }

                        this.loadRecommendedProducts(this.product.id)

                        this.sdk.trackAnalyticByProductId(this.product.id)
                        this.dataStore.setRecentlyViewed(this.product)
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            loadRecommendedProducts: function (productId) {
                this.sdk.getRecommendedProducts([productId])
                    .then(response => {
                        if (response.recommended_products.length === 0) {
                            this.recommendedProducts = []
                            return;
                        }

                        this.recommendedProducts = response.recommended_products;

                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            addProductToBasket: function (productId) {
                if (!this.dataStore.basket) {
                    this.sdk.createBasket([
                        {
                            product_id: productId,
                            quantity: this.quantity,
                        },
                    ]).then(response => {
                        const basket = response.basket;
                        this.dataStore.setBasket(basket);
                        this.emitter.emit('basket-updated', productId);
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    })

                    return
                }

                this.sdk.addProductToBasket(this.dataStore.getBasketReference(), productId, this.quantity)
                    .then(response => {
                        const basket = response.basket;
                        this.dataStore.setBasket(basket);
                        this.emitter.emit('basket-updated', productId);
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            selectProductVariant: function(productId){
                const selectedProducts = this.productVariants.filter(product => {
                    return product.id === parseInt(productId)
                });

                this.selectedProduct = selectedProducts[0]
                this.selectedProductId = this.selectedProduct.id

                history.pushState(
                    {},
                    null,
                    this.$router.resolve({name: 'product', params: { slug: this.selectedProduct.slug }}).href
                )
            },
            shouldShowBuyNowButtons: function(){
                if(window.scrollY >= this.$refs['product-info'].clientHeight){
                    this.showBuyNowButtons = true;
                    return;
                }

                this.showBuyNowButtons = false
            }
        },
        created() {
            this.loadProduct();
            this.loadPageContent();
            window.addEventListener('scroll', this.shouldShowBuyNowButtons);
        }
    }
</script>