<template>
    <div class="border-b border-gray py-4" :class="componentThemeClass">
        <a @click.prevent="open = !open" data-aos="fade-right">
            <h5 class="mb-0 flex items-center justify-between">{{ title }}
                <template v-if="iconStyle === 'arrow'">
                    <ChevronUpIcon v-if="open" class="inline h-8 w-8" />
                    <ChevronDownIcon v-else class="inline h-8 w-8" />
                </template>
                <template v-else>
                    <MinusIcon v-if="open" class="inline h-5 w-5" />
                    <PlusIcon v-else class="inline h-5 w-5" />
                </template>
            </h5>
        </a>
        <div class="mt-4" :class="{'hidden' : !open}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import { MinusIcon, PlusIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid'

    export default {
        name: 'Accordion',
        mixins: [ComponentTheme],
        components: {
            MinusIcon, PlusIcon, ChevronDownIcon, ChevronUpIcon
        },
        props: {
            title: String,
            iconStyle: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                open: false
            }
        }
    }
</script>