<template>
    <section v-if="products && products.length > 0" :class="componentThemeClass">
        <Container container-class="px-4 py-16 lg:py-32">
            <div class="col-12 mb-8 lg:mb-10" :class="alignment ? 'text-' + alignment : ''">
                <h2 v-if="title" class="w-full mb-6 lg:mb-8" data-aos="fade-right">{{ title }}</h2>
                <LinkButton v-if="linkUrl" :href="linkUrl" :title="'Link: ' + linkText" :text-colour="linkTextColour" background-colour="transparent" border-colour="transparent" data-aos="fade-in">{{ linkText }}</LinkButton>
            </div>
            <div class="grid grid-flow-col auto-cols-3/4 pb-8 gap-4 lg:auto-cols-1/4" :class="[_backgroundColour === 'white' ? 'scroll-bar-dark' : 'scroll-bar']">
                <div v-for="(product, index) in products" :key="index" data-aos="fade-in" :data-aos-delay="index * 50">
                    <Product :product="product" />
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Product from '@/components/elements/Product.vue'
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'RelatedProductSection',
        mixins: [ComponentTheme],
        components: {
            Product,
            Container,
            LinkButton
        },
        props: {
            title: String,
            linkUrl: String,
            linkText: String,
            products: Array,
            alignment: String
        },
        computed: {
            linkTextColour: function(){
                if(this._backgroundColour === 'white' || this._backgroundColour === 'transparent'){
                    return 'primary'
                }

                return this._textColour;
            }
        }
    }
</script>