<template>
    <section class="relative bg-cover bg-center flex items-center h-600px" :class="componentThemeClass" :style="image ? {backgroundImage: `url('${image}')`} : {}">
        <Container container-class="px-4 lg:max-w-4xl">
            <blockquote data-aos="fade-right">
                <p class="text-2xl lg:text-3xl mb-4">"{{ quote }}"</p>
                <footer>- {{ author }}</footer>
            </blockquote>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'QuoteSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            quote: String,
            author: String,
            image: String
        },
        created() {
            if(this.image){
                this._backgroundColour = null
            }
        }
    }
</script>