<template>
    <header class="relative bg-cover lg:bg-contain bg-top bg-no-repeat flex items-center h-screen" :class="componentThemeClass">
        <Navigation :background-colour="_backgroundColour" :text-colour="_textColour" position="absolute" />
        <ContainerFluid container-class="w-full">
            <div class="grid grid-cols-12 h-screen">
                <div class="col-span-12 lg:col-span-6 self-center py-4 px-4 lg:py-64 lg:px-32 text-center" :data-aos="index % 2 ? 'fade-left' : 'fade-right'">
                    <h1 class="h2">
                        {{ title }}
                        <small v-if="subTitle" class="block mt-4 lg:text-2xl">- {{subTitle}}</small>
                    </h1>
                    <p v-if="text" class="mb-0 mt-8 text-2xl">{{ text }}</p>
                </div>
                <div class="col-span-12 lg:col-span-6 bg-cover bg-top h-full" :class="{'lg:order-2': index % 2 === 0}" :data-aos="index % 2 ? 'fade-right' : 'fade-left'" :style="{backgroundImage: `url('${backgroundImage}')`}">
                </div>
            </div>
        </ContainerFluid>
    </header>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import Navigation from '@/components/Navigation.vue'
    import ContainerFluid from '@/components/ContainerFluid'

    export default {
        name: 'ImageMasthead',
        mixins: [ComponentTheme],
        components: {
            Navigation,
            ContainerFluid
        },
        props: {
            backgroundImage: String,
            title: String,
            subTitle: String,
            text: String,
        },
        created() {
            if(this.image){
                this._backgroundColour = null
            }
        }
    }
</script>