<template>
    <a :href="link" class="border uppercase transition-opacity text-center py-3 px-6 hover:opacity-90 disabled:opacity-50 disabled:pointer-events-none rounded-theme lg:text-lg" :class="buttonClass">
        <slot></slot>
    </a>
</template>

<script>
    import SiteContent from '@/mixins/siteContent'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'LinkButton',
        inject: ['dataStore'],
        mixins: [ComponentTheme, SiteContent],
        props: {
            link: String,
            customClass: String,
            rounded: Boolean,
            borderColour: {
                type: String,
                default: null
            },
        },
        computed: {
            buttonClass: function () {
                let cssClass = [this.componentThemeClass];

                if(!this.borderColour) {
                    cssClass.push('border-' + this._backgroundColour)
                }
                else{
                    cssClass.push('border-' + this.borderColour)
                }

                return cssClass.join(' ');
            }
        },
        created() {
            this._backgroundColour = this.backgroundColour ? this.backgroundColour : this.siteSettings.value.theme_button_background_colour;
            this._textColour = this.textColour ? this.textColour : this.siteSettings.value.theme_button_text_colour;
        }
    }
</script>



