import {createRouter, createWebHistory} from 'vue-router'
import HomePage from '@/pages/HomePage';
import GeneralPage from '@/pages/GeneralPage';
import ProductListPage from '@/pages/ProductListPage';
import ProductPage from '@/pages/ProductPage';
import BasketPage from '@/pages/BasketPage';
import LoginPage from '@/pages/LoginPage';
import PasswordResetPage from '@/pages/PasswordResetPage';
import ActivationPage from '@/pages/ActivationPage';
import AccountPage from '@/pages/AccountPage';
import AccountOrderListPage from '@/pages/AccountOrderListPage';
import AccountOrderPage from '@/pages/AccountOrderPage';
import AccountDetailsPage from '@/pages/AccountDetailsPage';
import AccountAddressPage from '@/pages/AccountAddressPage';
import CheckoutPage from '@/pages/CheckoutPage';
import NotFoundPage from '@/pages/NotFoundPage';
import CollectionListPage from '@/pages/CollectionListPage';
import CollectionPage from '@/pages/CollectionPage';
import SearchPage from '@/pages/SearchPage';
import data from '@/store/data';

const authenticationGuard = function (to, from, next) {
    if (data.getUser()) {
        next();
    } else {
        next('/login');
    }
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/pages/:slug',
        name: 'page',
        component: GeneralPage
    },
    {
        path: '/products/:type?',
        name: 'products',
        component: ProductListPage
    },
    {
        path: '/product/:slug',
        name: 'product',
        component: ProductPage
    },
    {
        path: '/collections',
        name: 'collections',
        component: CollectionListPage
    },
    {
        path: '/collection/:slug',
        name: 'collection',
        component: CollectionPage
    },
    {
        path: '/search',
        name: 'search',
        component: SearchPage
    },
    {
        path: '/basket',
        name: 'basket',
        component: BasketPage
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: CheckoutPage
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordResetPage
    },
    {
        path: '/activate',
        name: 'activate',
        component: ActivationPage
    },
    {
        path: '/account',
        name: 'account',
        component: AccountPage,
        beforeEnter : authenticationGuard,
    },
    {
        path: '/account/orders',
        name: 'orders',
        component: AccountOrderListPage,
        beforeEnter : authenticationGuard
    },
    {
        path: '/account/orders/:code',
        name: 'order',
        component: AccountOrderPage,
        beforeEnter : authenticationGuard
    },
    {
        path: '/account/details',
        name: 'details',
        component: AccountDetailsPage,
        beforeEnter :authenticationGuard
    },
    {
        path: '/account/address',
        name: 'address',
        component: AccountAddressPage,
        beforeEnter : authenticationGuard
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'catchall',
        component: NotFoundPage
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0}
    }
});

export default router