<template>
    <transition name="fade" mode="out-in">
        <div v-if="pageContent">
            <GlobalAlert />
            <Masthead v-if="masthead"
                      :background-colour="masthead.value.background_colour"
                      :background-image="masthead.value.background_image"
                      :text-colour="masthead.value.text_colour"
                      :title="masthead.value.title"
                      :sub-title="masthead.value.sub_title"
                      :text="masthead.value.text"
                      :text-alignment="masthead.value.text_alignment"
                      :image-position="masthead.value.image_position"
                      :images="masthead.value.images"
                      :links="masthead.value.links"
            />
            <main>
                <ContentSections :pageContent="pageContent"/>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import Masthead from '@/components/Masthead.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import Footer from '@/components/Footer.vue'
    import PageContent from "@/mixins/pageContent"

    export default {
        name: 'HomePage',
        inject: ['sdk', 'dataStore'],
        mixins: [PageContent],
        components: {
            GlobalAlert,
            Masthead,
            ContentSections,
            Footer
        },
        computed: {
            pageSlug: function () {
                return 'home';
            },
            masthead: function () {
                return this.getPageContentByKey('masthead')
            }
        },
        created() {
            this.loadPageContent();
        }
    }
</script>