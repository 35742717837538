
<template>
    <transition name="fade" mode="out-in">
        <div v-if="loaded">
            <GlobalAlert />
            <TextMasthead title="Activate your account" :small-heading="true" :class="'border-b border-gray'" />
            <main>
                <section>
                    <Container container-class="px-4 py-16 lg:py-20">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 mb-16 pb-16 lg:mb-0 lg:pb-0 lg:border-b-0 lg:col-span-6 lg:pr-32" data-aos="fade-up">
                                <h3 class="mb-8">Activate account</h3>
                                <template v-if="activateAccountSuccess">
                                    <p class="text-xl">Your account has been successfully activated.</p>
                                    <p>You can now <router-link :to="{name: 'login'}" title="Link: Login" class="font-bold">login</router-link> to your account.</p>
                                </template>
                                <template v-if="resendActivationSuccess">
                                    <p class="text-xl">Account activation request has been successfully created.</p>
                                    <p>Check your emails to activate your account.</p>
                                </template>
                                <template v-if="!activateAccountSuccess && !resendActivationSuccess">
                                    <p>To request a new activation, enter your email address below and we will email you instructions.</p>
                                    <Alert v-if="resendActivationErrors.length > 0" :messages="resendActivationErrors" />
                                    <Alert v-if="activateAccountErrors.length > 0" :messages="activateAccountErrors" />
                                    <form v-on:submit.prevent="resendActivation($event)">
                                        <div class="mb-4">
                                            <label>
                                                <span class="text-gray-700">Email address*</span>
                                                <input name="email" type="email" class="w-full" required />
                                            </label>
                                        </div>
                                        <Button :class="'mt-6'">Resend activation</Button>
                                    </form>
                                </template>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    </transition>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Alert from '@/components/elements/Alert.vue'
    import Button from '@/components/elements/Button.vue'
    import Footer from '@/components/Footer.vue'
    import Container from '@/components/Container'

    export default {
        name: 'ActivationPage',
        inject: ['dataStore', 'sdk'],
        components: {
            Container,
            GlobalAlert,
            TextMasthead,
            Alert,
            Button,
            Footer
        },
        computed: {
            code: function () {
                return 'code' in this.$route.query ? this.$route.query.code : null;
            }
        },
        data() {
            return {
                loaded: false,
                resendActivationErrors: [],
                resendActivationSuccess: false,
                activateAccountErrors: [],
                activateAccountSuccess: false,
            };
        },
        methods: {
            resendActivation: function (event) {
                this.resendActivationErrors = [];
                this.resendActivationSuccess = false;

                this.sdk.resendActivation(event.target.elements.email.value).then(response => {
                    console.debug(response)
                   this.resendActivationSuccess = true;
                }).catch(error => {
                    if('errors' in error.response.data){
                        this.resendActivationErrors = [
                            error.response.data.errors[0].message
                        ];
                    }
                    else {
                        this.resendActivationErrors = [
                            'Error processing your request'
                        ];
                    }
                })
            },
            activateAccount: function () {
                this.activateAccountErrors = [];
                this.activateAccountSuccess = false;

                this.sdk.activateAccount(this.code).then(response => {
                    console.debug(response)
                    this.activateAccountSuccess = true;
                }).catch(error => {
                    if('errors' in error.response.data){
                        this.activateAccountErrors = [
                            error.response.data.errors[0].message
                        ];
                    }
                    else {
                        this.activateAccountErrors = [
                            'Error processing your request'
                        ];
                    }
                }) .finally(() => {
                    this.loaded = true
                });
            }
        },
        created() {
            if(this.code) {
                this.activateAccount()
            }
            else{
                this.loaded = true
            }
        }
    }
</script>