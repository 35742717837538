<template>
    <nav v-if="store" class="w-full top-0 py-4 z-10 lg:py-16" :class="navClass">
        <Container container-class="px-4">
            <div class="flex items-center justify-between">
                <div>
                    <router-link :to="{name: 'home'}" title="Link: Home">
                        <img :src="_textColour === 'white' ? siteSettings.value.light_logo : siteSettings.value.dark_logo" :alt="'Image: ' + store.name + ' Logo'" class="h-12 lg:h-16"/>
                    </router-link>
                </div>
                <div class="hidden flex items-center lg:block">
                    <ul v-if="primaryNavigation" class="px-4 flex lg:pr-0 uppercase font-medium" :class="textColourClass">
                        <li v-for="(link, index) in primaryNavigation.value.links" :key="index" class="mr-8">
                            <a class="border-b-4 border-transparent hover:border-current pb-1" :href="link.url" :title="'Link: ' + link.title">
                                {{ link.title }}
                            </a>
                        </li>
                        <li class="mr-6">
                            <a :title="'Link: Search'" @click.prevent="searchToggle"><SearchIcon  class="inline h-6 w-6" /></a>
                        </li>
                        <li class="mr-6">
                            <router-link :to="{ name: 'basket'}" title="Link: Basket" class="relative">
                                <ShoppingBagIcon class="inline h-6 w-6" /><span class="absolute text-center -top-2 -right-2 min-w-16px px-1 bg-primary rounded-full text-xs text-white">{{ basket ? basket.products.length : '0' }}</span>
                            </router-link>
                        </li>
                        <li v-if="!dataStore.loggedIn()">
                            <router-link :to="{ name: 'login'}" title="Link: Login"><ProfileIcon class="inline h-6 w-6" /></router-link>
                        </li>
                        <li v-if="dataStore.loggedIn()" class="mr-6">
                            <router-link :to="{ name: 'account'}" title="Link: Account"><ProfileIcon class="inline h-6 w-6" /></router-link>
                        </li>
                        <li v-if="dataStore.loggedIn()">
                            <a href="#" @click="logout()"><LogoutIcon class="inline h-6 w-6" /></a>
                        </li>
                    </ul>
                </div>
                <transition name="fade" mode="out-in">
                    <div v-if="menuOpen" class="z-50 top-0 left-0 fixed block h-screen w-screen bg-white lg:hidden">
                        <a class="absolute text-black top-7 right-4 z-50 lg:hidden" @click.prevent="menuToggle">
                            <XIcon class="inline h-8 w-8" />
                        </a>
                        <div class="relative h-full flex items-center">
                            <ul class="px-4 text-black text-2xl uppercase font-medium">
                                <li v-for="(link, index) in primaryNavigation.value.links" :key="index" class="mb-2">
                                    <a :href="link.url" :title="'Link: ' + link.title">{{ link.title }}</a>
                                </li>
                                <li class="mb-2">
                                    <router-link :to="{ name: 'search'}" title="Link: Search">Search</router-link>
                                </li>
                                <li class="mb-2">
                                    <router-link :to="{ name: 'basket'}" title="Link: Basket">Basket({{ basket ? basket.products.length : '0' }})</router-link>
                                </li>
                                <li v-if="!dataStore.loggedIn()">
                                    <router-link :to="{ name: 'login'}" title="Link: Login">Login</router-link>
                                </li>
                                <li v-if="dataStore.loggedIn()" class="mb-2">
                                    <router-link :to="{ name: 'account'}" title="Link: Account">Account</router-link>
                                </li>
                                <li v-if="dataStore.loggedIn()">
                                    <a href="#" @click="logout()">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </transition>
                <div class="block lg:hidden">
                    <a @click.prevent="menuToggle">
                        <MenuIcon class="inline h-8 w-8" :class="textColourClass" />
                    </a>
                </div>
            </div>
            <BasketAlert ref="basket" />
        </Container>

        <div v-if="searchOpen" class="hidden lg:block">
            <Container container-class="px-4 py-16 lg:max-w-4xl">
                <form :action="searchFormUrl" class="flex items-center border-b-2" :class="'text-' + _textColour + ' border-' + _textColour">
                    <button><SearchIcon class="inline h-8 w-8" /></button>
                    <input type="text" name="query" class="bg-transparent text-2xl w-full border-none focus:ring-0" placeholder="What are you looking for?" />
                    <a @click.prevent="searchToggle"><XIcon class="inline h-8 w-8" /></a>
                </form>
            </Container>
        </div>
    </nav>
</template>

<script>
    import { MenuIcon, XIcon } from '@heroicons/vue/solid'
    import ShoppingBagIcon from '@/icons/Basket'
    import SearchIcon from '@/icons/Search'
    import ProfileIcon from '@/icons/Profile'
    import LogoutIcon from '@/icons/Logout'
    import Container from '@/components/Container'
    import BasketAlert from '@/components/BasketAlert'
    import SiteContent from '@/mixins/siteContent'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'Navigation',
        inject: ['dataStore'],
        mixins: [ComponentTheme, SiteContent],
        components: {
            Container,
            BasketAlert,
            MenuIcon,
            XIcon,
            ShoppingBagIcon,
            ProfileIcon,
            LogoutIcon,
            SearchIcon
        },
        props: {
            position: String
        },
        data() {
            return {
                menuOpen: false,
                searchOpen: false
            }
        },
        computed: {
            store: function () {
                return this.dataStore.state.store
            },
            basket: function () {
                return this.dataStore.state.basket
            },
            primaryNavigation: function () {
                return this.getSiteContentByKey('primary_navigation')
            },
            searchFormUrl: function () {
                return this.$router.resolve({name: 'search'}).href
            },
            navClass: function(){
                let navClass = [];

                if(this.position === 'absolute'){
                    navClass.push('absolute')
                }

                if(this.searchOpen && this._textColour === 'white'){
                    navClass.push('lg:bg-black lg:text-black', 'lg:filter', 'lg:drop-shadow-md');
                }

                if(this.searchOpen && this._textColour === 'black'){
                    navClass.push('lg:bg-white lg:text-white', 'lg:filter', 'lg:drop-shadow-md');
                }

                return navClass.join(' ');
            }
        },
        methods: {
            menuToggle: function () {
                this.menuOpen = !this.menuOpen
            },
            basketToggle: function () {
                this.$refs.basket.basketToggle()
            },
            searchToggle: function () {
                this.searchOpen = !this.searchOpen

                if (this._textColour === 'black'){
                    this._textColour = 'white';
                } else {
                    this._textColour = 'black';
                }
            },
            logout: function(){
                this.dataStore.logout()
                this.$router.push({name: 'login'});
            }
        }
    }
</script>