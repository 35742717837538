<template>
    <section v-if="group && group.products.length > 0" :class="componentThemeClass">
        <Container container-class="px-4 py-16 lg:py-32">
            <div class="grid grid-cols-12 gap-4 lg:gap-16 items-center">
                <div v-if="group.thumbnail" class="col-span-12 lg:col-span-6" :class="{'lg:order-2': imageFlip}" >
                    <img :src="group.thumbnail.url" :alt="'Image: ' + group.title" />
                </div>
                <div class="col-span-12" :class="{'lg:col-span-6': group.thumbnail}">
                    <h2 class="h1 text-center mb-8 lg:mb-16 lg:text-left" :class="'text-' + headingColour" data-aos="fade-down">{{ group.title }}</h2>
                    <div class="grid grid-flow-col auto-cols-3/4 lg:auto-cols-1/2 pb-8 gap-4" :class="[_backgroundColour === 'white' ? 'scroll-bar-dark' : 'scroll-bar']">
                        <div v-for="(product, index) in group.products" :key="index" data-aos="fade-in" :data-aos-delay="index * 50">
                            <Product :product="product" />
                        </div>
                    </div>
                    <LinkButton :href="$router.resolve({name: 'collection', params: { slug: group.slug }}).href" :title="'Link: ' + group.title" :text-colour="headingColour" :border-colour="headingColour"
                               background-colour="transparent" class="block mt-8 text-xl" data-aos="fade-up">View collection</LinkButton>
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container'
    import Product from '@/components/elements/Product.vue'
    import ComponentTheme from '@/mixins/componentTheme'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'ProductGroupSectionWithImage',
        inject: ['sdk', 'errorHandler'],
        mixins: [ComponentTheme],
        components: {
            Product,
            Container,
            LinkButton
        },
        props: {
            productGroupSlug: String,
            productGroup: {
                type: Object,
                default: null
            },
            imageFlip: Boolean
        },
        data() {
            return {
                group: null
            }
        },
        computed: {
            headingColour: function(){
                if(this._backgroundColour === 'white' || this._backgroundColour === 'transparent'){
                    return 'primary'
                }

                return  this._textColour;
            }
        },
        methods: {
            loadProductGroup: function () {
                this.sdk.getProductGroupBySlug(this.productGroupSlug)
                    .then(response => {
                        this.group = response.product_groups[0]
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
        },
        created() {
            if (this.productGroup) {
                this.group = this.productGroup
            } else {
                this.loadProductGroup()
            }
        }
    }
</script>