<template>
    <header :class="componentThemeClass">
        <Navigation :background-colour="_backgroundColour" :text-colour="_textColour" />
        <template v-if="title">
            <Container container-class="px-4 pb-1 pt-16 lg:pt-16" animation="fade-right">
                <div class="py-16" :class="{'mx-auto text-center': centerText}" >
                    <h1 class="mb-0" :class="{'h2' : smallHeading}">{{ title }}
                        <small v-if="subTitle" class="block lg:text-7xl">- {{subTitle}}</small>
                    </h1>
                    <p v-if="text" class="mb-0 mt-8 lg:text-lg lg:max-w-4xl">{{ text }}</p>
                </div>
            </Container>
        </template>
    </header>
</template>

<script>
    import Container from '@/components/Container'
    import ComponentTheme from '@/mixins/componentTheme'
    import Navigation from '@/components/Navigation.vue'

    export default {
        name: 'TextMasthead',
        mixins: [ComponentTheme],
        components: {
            Container,
            Navigation
        },
        props: {
            title: String,
            subTitle: String,
            text: String,
            centerText: Boolean,
            smallHeading: Boolean
        }
    }
</script>