<template>
    <footer>
        <div v-if="outputNavigation" :class="['bg-' + footerContent.value.background_colour]">
            <Container container-class="py-16 px-4 lg:py-24">
                <div class="grid grid-cols-12 gap-4 lg:gap-8">
                    <div v-if="footerContent.value.panel_1" class="mb-4 col-span-6 lg:col-span-3 lg:mb-0" :class="['text-' + footerContent.value.text_colour]" data-aos="fade-in">
                        <ul v-if="footerContent.value.panel_1.title">
                            <li class="font-medium pb-5 lg:text-xl">{{ footerContent.value.panel_1.title }}</li>
                            <li v-for="(link, index) in footerContent.value.panel_1.content" :key="index" class="mb-2">
                                <a :href="link.link_url" :title="'Link: ' + link.title">{{ link.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="footerContent.value.panel_2" class="mb-4 col-span-6 lg:col-span-3 lg:mb-0" :class="['text-' + footerContent.value.text_colour]" data-aos="fade-in" data-aos-delay="100">
                        <ul v-if="footerContent.value.panel_2.title">
                            <li class="font-medium pb-5 lg:text-xl">{{ footerContent.value.panel_2.title }}</li>
                            <li v-for="(link, index) in footerContent.value.panel_2.content" :key="index" class="mb-2">
                                <a :href="link.link_url" :title="'Link: ' + link.title">{{ link.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="footerContent.value.contact" class="mb-4 col-span-6 lg:col-span-3 lg:mb-0" :class="['text-' + footerContent.value.text_colour]" data-aos="fade-in" data-aos-delay="200">
                        <ul v-if="footerContent.value.contact.title">
                            <li class="font-medium pb-5 lg:text-xl">{{ footerContent.value.contact.title }}</li>
                            <li v-for="(link, index) in footerContent.value.contact.content" :key="index" class="mb-2">
                                <a class="block" v-if="link.link_url" :href="link.link_url" :title="'Link: ' + link.title">{{ link.title }}</a>
                                <span class="block" v-else>{{ link.title }}</span>
                            </li>
                        </ul>
                    </div>
                    <div v-if="footerContent.value.social" class="mb-4 col-span-6 lg:col-span-3 lg:mb-0" :class="['text-' + footerContent.value.text_colour]" data-aos="fade-in" data-aos-delay="300">
                        <ul v-if="footerContent.value.social.title">
                            <li class="font-medium pb-5 lg:text-xl">{{ footerContent.value.social.title }}</li>
                            <li v-for="(link, index) in footerContent.value.social.content" :key="index" class="mb-2 inline">
                                <a :href="link.social_link" :title="'Link: ' + link.social">
                                    <img class="inline pr-4" :src="'/icons/' + link.social + '.svg'" :alt="'Link: ' + link.social">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
        <div v-if="store && footerContent.value.back_link_text && footerContent.value.back_link_url" class="bg-white text-black">
            <Container container-class="p-4 text-center font-bold text-primary">
                BACK TO <a :href="footerContent.value.back_link_url">{{ footerContent.value.back_link_text }}</a>
            </Container>
        </div>
    </footer>
</template>

<script>
    import Container from '@/components/Container'
    import SiteContent from '@/mixins/siteContent'

    export default {
        name: 'Footer',
        inject: ['dataStore'],
        mixins: [SiteContent],
        components: {Container},
        props: {
            outputNavigation: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            store: function () {
                return this.dataStore.state.store
            },
            footerContent: function () {
                return this.getSiteContentByKey('footer')
            }
        },
    }
</script>