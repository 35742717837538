export default {
    computed: {
        site: function () {
            return this.dataStore.state.site
        },
        siteSettings: function () {
            return this.getSiteContentByKey('site_settings')
        },
    },
    methods: {
        getSiteContentByKey: function (key) {
            if(this.site && this.site.content.length > 0) {
                const content = this.site.content.filter(content => content.content_type && content.content_type.key === key)

                if (content.length > 0) {
                    return content[0]
                }
            }

            return null;
        }
    }
}