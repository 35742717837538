<template>
    <header class="relative bg-cover lg:bg-contain bg-top bg-no-repeat flex items-center h-screen lg:min-h-screen lg:h-auto" :class="componentThemeClass" :style="backgroundImage ? {backgroundImage: `url('${backgroundImage}')`} : ''">
        <Navigation :background-colour="_backgroundColour" :text-colour="_textColour" position="absolute" />

        <div v-if="images" class="absolute top-20 bottom-0 grid grid-cols-12 grid-rows-12 w-full lg:top-0" data-aos="fade-in">
            <template v-if="imagePosition === 'side-by-side'">
                <div v-if="images[0]" class="col-span-8 row-span-5 col-start-5 bg-contain bg-no-repeat bg-right-top lg:row-span-9 lg:row-start-4 lg:col-span-4 lg:col-start-5 lg:bg-cover" :style="{backgroundImage: `url('${images[0].image.url}')`}"></div>
                <div v-if="images[1]" class="col-span-8 row-span-5 row-start-7 bg-contain bg-no-repeat bg-left-bottom lg:row-span-9 lg:row-start-4 lg:col-span-4 lg:bg-cover" :style="{backgroundImage: `url('${images[1].image.url}')`}"></div>
            </template>
            <template v-else>
                <div v-if="images[0]" class="col-span-8 row-span-5 col-start-5 bg-contain bg-no-repeat bg-right-top lg:row-span-9 lg:col-span-6 lg:col-start-7" :style="{backgroundImage: `url('${images[0].image.url}')`}"></div>
                <div v-if="images[1]" class="col-span-8 row-span-5 row-start-7 bg-contain bg-no-repeat bg-left-bottom lg:row-span-9 lg:row-start-4 lg:col-span-6" :style="{backgroundImage: `url('${images[1].image.url}')`}"></div>
            </template>
        </div>

        <Container container-class="px-4 py-16 lg:pt-44 lg:pb-32 lg:max-w-6xl relative h-full flex flex-col justify-center" :class="textAlignment ? 'text-' + textAlignment : 'text-center'">
            <h1 class="flex flex-col">
                <span data-aos="fade-right" class="text-left" :class="{'lg:self-start lg:max-w-1/2' : subTitle}">{{ title }}</span>
                <span v-if="subTitle" class="relative text-right lg:self-start lg:mt-8 lg:left-1/2 lg:max-w-1/2 lg:text-left" data-aos="fade-left">{{ subTitle }}</span>
            </h1>
            <p v-if="text" class="mb-0 mt-8 text-2xl">{{ text }}</p>
            <div v-if="links" class="absolute bottom-8 right-4 lg:relative lg:right-auto lg:bottom-auto lg:w-1/2 lg:text-left lg:mt-8 lg:self-end">
                <LinkButton v-for="(link, index) in links" :key="index" :link="link.link_url" class="block mb-2 lg:inline-block lg:ml-2">
                    {{ link.title }}
                </LinkButton>
            </div>
        </Container>
    </header>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import Navigation from '@/components/Navigation.vue'
    import Container from '@/components/Container'
    import LinkButton from "./elements/LinkButton";

    export default {
        name: 'Masthead',
        mixins: [ComponentTheme],
        components: {
            LinkButton,
            Navigation,
            Container
        },
        props: {
            backgroundImage: String,
            title: String,
            subTitle: String,
            text: String,
            textAlignment: String,
            imagePosition: String,
            images: Array,
            links: Array
        },
        created() {
            if(this.image){
                this._backgroundColour = null
            }
        }
    }
</script>